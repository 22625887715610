import emailjs from "emailjs-com";
import React from 'react';

var message1 = "Want to connect? No problem! Send me an email"
var message2 = "and I'll make sure to get back to you."
var city = "Stockholm"
var state = ""
var zip = ""
var phone = "+46 703 16 45 38"
var name = "Jesper Eriksson"

export default function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    
        emailjs.sendForm('gmail', 'template_g7jxwqe', e.target, 'user_RVHnkC0bwEGCoYSfI5iEr')
          .then((result) => {
              window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          }, (error) => {
              console.log(error.text);
          });
    }
    
    return(
        <section id="contact">

        <div className="row section-head">

           <div className="two columns header-col">

              <h1><span>Get In Touch</span></h1>
               
           </div>

           <div className="ten columns">

                 <p className="lead">{message1}<br />
                 {message2}</p>

           </div>

        </div>

        <div className="row">
           <div className="eight columns">

              <form onSubmit={sendEmail} action="" method="post" id="contactForm" name="contactForm">
                   <fieldset>

                 <div>
                          <label htmlFor="contactName">Name <span className="required">*</span></label>
                          <input type="text" defaultValue="" size="35" id="contactName" name="name" />
                 </div>

                 <div>
                          <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                          <input type="text" defaultValue="" size="35" id="contactEmail" name="email" />
                 </div>

                 <div>
                          <label htmlFor="contactSubject">Subject</label>
                          <input type="text" defaultValue="" size="35" id="contactSubject" name="subject" />
                 </div>

                 <div>
                    <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                    <textarea cols="50" rows="15" id="contactMessage" name="message"></textarea>
                 </div>

                 <div>
                    <button type="submit" className="btn btn-info" >Submit</button>
                    <span id="image-loader">
                       <img alt="" src="images/loader.gif" />
                    </span>
                    {/* <input type="submit" className="btn btn-info"></input> */}
                 </div>
                   </fieldset>
                  </form>

          <div id="message-warning">Error!</div>
                  <div id="message-success">
                 <i className="fa fa-check"></i>Your message was sent, thank you!<br />
                  </div>
          </div>
          
           <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                      <h4>Address and Phone</h4>
                      <p className="address">
                          {name}<br />
                          {city} {state} {zip}<br />
                          <span>{phone}</span>
                      </p>
                  </div>
           </aside>
     </div>
  </section>
    )
}