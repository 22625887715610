import React, { Component } from 'react';

class thiswebpage extends Component {
  render() {

    if(this.props.data){
      var thiswebpage = this.props.data.thiswebpage.map(function(thiswebpage){
        return  <li key={thiswebpage.user}>
               <h1>Webpage Architecture</h1>
               <p>{thiswebpage.text}</p>
               <img className="architecture-pic"  src={thiswebpage.pic} alt="architecture description" />
         </li>
      })
    }

    return (
      <section id="thiswebpage">
      <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
               <h1><span></span></h1>
            </div>

            <div className="ten columns flex-container">
                  <ul className="slides">
                      {thiswebpage}
                  </ul>
               </div>
            </div>
         </div>
   </section>
    );
  }
}

export default thiswebpage;
